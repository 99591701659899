<template>
  <div class="ml-5">
    <v-card flat color="transparent">
      <h2>My Balance: ${{ getRunningBalance }}</h2>
    <v-btn v-if="this.getSession.user_type === 1" class="float-right mt-n7" :color="essayGreen" @click="show=true"> Add funds</v-btn>
    </v-card>
     <v-card flat class="mt-5" color="transparent">
      <v-tabs background-color="transparent" class="mt-3">
        <v-tab key="tab1" to="/dashboard/finance/transaction"><v-icon class="mr-2">mdi-alarm-panel-outline</v-icon> Transactions</v-tab>
        <v-tab key="tab2" to="/dashboard/finance/withdrawal" v-if="getSession.user_type === 2"> <v-icon class="mr-2">>mdi-badge-account-horizontal-outline</v-icon>Withdrawal Requests</v-tab>
        <v-tab key="tab3" to="/dashboard/finance/refunds" v-else><v-icon class="mr-2">mdi-credit-card</v-icon> Refunds</v-tab>
        <v-tab key="tab3" to="/dashboard/finance/payment-accounts"><v-icon class="mr-2">mdi-credit-card</v-icon> Payment Method</v-tab>
      </v-tabs>
      <v-divider class="mt-0" color="grey"></v-divider>
    </v-card>
    <router-view class="mt-5"></router-view>
    <PaymentModal :show="show" description="Deposit from Bank card" @close="show=false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "Balance",
  components: {
    PaymentModal: () => import('@/modules/PaymentModal'),
  },
  mixins: [colorMxn],
  data() {
    return {
      msg: "hey message",
      show: false,
    };
  },
  computed: {
    ...mapGetters(['getSession', 'getRunningBalance'])
  },
  methods: {
    callbackMethod() {
      
    }
  }
};
</script>

<style>
.card-header {
  background: #232c3b;
  height: 70px;
  border: 2px solid #273142;
  border-radius: 4px;
}

.icon {
  width: 70px;
  text-align: left;
}

.pending {
  color: #286bb8;
}

.successful {
  color: #038c43;
}

.failed {
  color: red;
}
</style>
